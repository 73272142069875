<style lang="less">
@import "./addUser.less";
</style>

<template>
  <div class="add-user">
    <div class="add-user-con">
      <div icon="log-in" title="添加用户" :bordered="false">
        <add-user-form @on-success-valid="handleSubmit"></add-user-form>
      </div>
    </div>
  </div>
</template>

<script>
import AddUserForm from "../../components/add-user-form";
export default {
  components: {
    AddUserForm
  },
  methods: {
    // ...mapActions(["handleLogin", "getUserInfo"]),

    handleSubmit({
      userName,
      password,
      email,
      nick_name,
      campus,
      faculty,
      type_privilege
    }) {
      // console.log(userName,nick_name, password);
      const userInfo = {
        username: userName,
        password: password,
        email: email,
        display_name: nick_name,
        campus: campus,
        faculty: faculty,
        user_type: type_privilege[0],
        privilege: type_privilege[1]
      };

      const userInfoJson = JSON.stringify(userInfo);
      // console.log(userInfoJson)

      this.axios
        .post("api/users", userInfoJson)
        .then(() => {
          alert("添加成功！");
          // console.log(resp)
        })
        .catch(() => {
          // console.log(error.response)
          // console.log("异常处理")
          this.$Message.error(`This username is exist!`);
        })
        .then(() => {
          this.$router.push("/manageUser");
        });
    }
  }
};
</script>
