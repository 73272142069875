<template>
  <Form
    ref="addUserForm"
    :model="form"
    :rules="rules"
    :label-width="80"
    @keydown.enter.native="handleSubmit"
  >
    <FormItem label="用户名" prop="userName">
      <Input
        v-model="form.userName"
        placeholder="用户名"
        autocomplete="username"
      >
        <Icon type="ios-person" :size="16" slot="prefix" />
      </Input>
    </FormItem>

    <FormItem label="密码" prop="password">
      <Input
        type="password"
        v-model="form.password"
        password
        placeholder="密码"
        autocomplete="new-password"
      >
        <Icon :size="14" type="md-lock" slot="prefix"></Icon>
      </Input>
    </FormItem>

    <FormItem label="确认密码" prop="repassword">
      <Input
        type="password"
        v-model="form.repassword"
        password
        placeholder="确认密码"
        autocomplete="new-password"
      >
        <Icon :size="14" type="md-lock" slot="prefix"></Icon>
      </Input>
    </FormItem>
    <Divider />
    <FormItem label="邮箱" prop="email">
      <Input v-model="form.email" placeholder="邮箱">
        <Icon type="ios-mail" :size="16" slot="prefix" />
      </Input>
    </FormItem>

    <FormItem label="昵称" prop="nickName">
      <Input v-model="form.nick_name" placeholder="昵称">
        <Icon :size="16" type="ios-person" slot="prefix"></Icon>
      </Input>
    </FormItem>
    <Divider />

    <FormItem label="学校" prop="campus">
      <!-- 第一个选择器  选择学校  -->
      <Select
        v-model="campus"
        placeholder="请选择学校"
        @on-change="handleCampusSelected"
      >
        <Icon type="ios-school" :size="16" slot="prefix" />
        <Option
          v-for="item in campusList"
          :value="item.value"
          :key="item.value"
        >
          {{ item.value }}
        </Option>
      </Select>
    </FormItem>

    <FormItem label="学院">
      <!-- 第二个选择器  选择学院  -->
      <Select v-model="faculty" placeholder="请选择学院">
        <Icon type="logo-buffer" :size="16" slot="prefix" />
        <Option
          v-for="item in facultyList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>
    <Divider />
    <FormItem label="权限">
      <Cascader :data="type_privilegeList" v-model="type_privilege"></Cascader>
    </FormItem>

    <Row>
      <Col span="3">
        <FormItem>
          <Button @click="handleSubmit" type="primary">确认添加</Button>
        </FormItem>
      </Col>
      <Col span="4" style="margin-left: 50px">
        <FormItem>
          <Button @click="handleBack" type="success">取消添加</Button>
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
export default {
  name: "AddUserForm",
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请重新输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("输入密码不匹配"));
      } else {
        callback();
      }
    };
    return {
      //  register from的数据绑定
      form: {
        userName: "",
        nick_name: "",
        email: "",
        password: ""
      },
      campus: "",
      campusList: [],
      faculty: "",
      facultyList: [],
      type_privilege: ["student", 0],
      type_privilegeList: [
        {
          value: "student",
          label: "student",
          children: [
            {
              value: 0,
              label: "0"
            }
          ]
        },
        {
          value: "teacher",
          label: "teacher",
          children: [
            {
              value: 1,
              label: "1"
            }
          ]
        },
        {
          value: "advisor",
          label: "advisor",
          children: [
            {
              value: 2,
              label: "2"
            },
            {
              value: 3,
              label: "3"
            }
          ]
        }
      ],
      rules: {
        userName: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur"
          },
          {
            type: "string",
            pattern:
              /^([a-zA-Z])[a-zA-Z_-]*[\w_-]*[\S]$|^([a-zA-Z])[0-9_-]*[\S]$|^[a-zA-Z]*[\S]$/,
            min: 3,
            max: 20,
            message: "3-20个字符，只含字母和数字",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur"
          },
          { type: "email", message: "邮箱格式错误", trigger: "blur" }
        ],
        nickName: [
          { required: false, trigger: "blur" },
          { type: "string", max: 10, trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            pattern:
              /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){0,})(?!.*\s).{8,20}$/,
            message: "8-20个字符，至少一个大写字母",
            trigger: "blur"
          }
        ],
        repassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validatePassCheck, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.addUserForm.validate(valid => {
        if (valid) {
          this.$emit("on-success-valid", {
            userName: this.form.userName,
            password: this.form.password,
            email: this.form.email,
            nick_name: this.form.nick_name,
            campus: this.campus,
            faculty: this.faculty,
            type_privilege: this.type_privilege
            // user_type: this.type_privilege[0],
            // privilege: this.type_privilege[1]
            // user_type: "teacher",
            // privilege: 1
          });
        }
      });
    },
    handleBack() {
      this.$router.push("/manageuser");
    },
    handleCampusSelected(selectedCampus) {
      this.axios.get(`api/campus/${selectedCampus}/faculties`).then(resp => {
        // console.log(resp)
        const facaulties = resp.data;
        this.facultyList = facaulties.map(faculty => ({ value: faculty }));
        this.faculty = facaulties[0];
      });
    }
  },
  mounted: function () {
    this.axios.get("api/campuses").then(resp => {
      // console.log(resp)
      const campuses = resp.data;
      this.campusList = campuses.map(campus => ({ value: campus }));
      //   设置默认值
      if (campuses) {
        //  设置默认的campus
        this.campus = campuses[0];
        //   把得到的 string array  转成  object array
        this.axios.get(`api/campus/${campuses[0]}/faculties`).then(resp1 => {
          // console.log(resp)
          const facaulties = resp1.data;
          //   把得到的 string array  转成  object array
          this.facultyList = facaulties.map(faculty => ({ value: faculty }));
          this.faculty = facaulties[0];
        });
      }
    });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
